import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { find, findIndex, take, intersection } from 'lodash';
import { Select2OptionData } from 'ng-select2';
import { forkJoin as observableForkJoin, Observable, of as observableOf, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CountriesService, LanguagesService, LocationsService, SpinnerService, ToastrService } from '../../';
import { Role, User } from '../../../user-management/user';
import { UserManagementService } from '../../../user-management/user-management.service';
import { regex } from '../../constants';
import { GetCitiesService } from '../../get-cities.service';
import { DatePickerOptions, LocationTypeEnum, RoleGroup, RoleGroupEnum } from '../../global-interfaces';
import { DynamicModalService } from '../dynamic-modal/dynamic-modal.service';

@Component({
    selector: 'add-user',
    templateUrl: './add-user.template.html'
})
export class AddUserModalComponent implements OnInit, AfterViewInit {
    @ViewChild('modalWindow') userModal;
    newUser: FormGroup;
    isFormSubmitted = false;
    setSubmitted = false;
    user: User;
    onClose: Subject<Function>;
    selectedLanguages: Array < string > = [];
    selectedLocations: Array < string > = [];
    cities: Array<Select2OptionData>;
    city: Array<string>;
    roles: Array<Role>;
    defaultRole: number;
    isCityDisabled = false;
    isTrainingScheduled = false;
    roleId: number;
    selectedCities: Array < string > = [];
    selectedCity: number;
    selectedCountry: number;
    locations: Array<Select2OptionData>;
    locationsLabel: string;
    languages: Array<Select2OptionData>;
    datePickerOptions: DatePickerOptions = {
        autoclose: true,
        todayHighlight: true
    };
    shouldSelectedMultipleLocations: boolean;
    isRevampVersion: boolean;
    countryList = [];
    selectedCountries: Array < string > = [];
    role_id_key: string = 'role_id';
    joining_date_key: string = 'joining_date';
    captainTypes = [];
    selectedCaptainTypes: Array < string > = [];

    select2Options: Select2Options = {
        multiple: true,
        theme: 'bootstrap4'
    };

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly userManagementService: UserManagementService,
        private readonly locationService: LocationsService,
        private readonly toastrService: ToastrService,
        private readonly spinnerService: SpinnerService,
        private readonly dynamicModalService: DynamicModalService,
        private readonly injector: Injector,
        private readonly languageService: LanguagesService,
        private readonly countriesService: CountriesService,
        private readonly citiesService: GetCitiesService    ) { }


    ngOnInit() {
        this.user = this.injector.get('user');
        this.selectedCity = this.injector.get('selectedCityId');
        this.onClose = this.injector.get('onClose');
        this.isRevampVersion = this.injector.get('isRevampVersion');
        this.role_id_key = this.isRevampVersion ? 'roleId' : 'role_id';

        observableForkJoin([
            this.userManagementService.getRoles(this.isRevampVersion),
            this.countriesService.getCountryList(false, this.isRevampVersion),
            this.citiesService.getCities(false, this.isRevampVersion)])
            .subscribe((response: any) => {
                this.roles = response[0]?.map((role) => {
                    role.text = role?.text || role?.displayName;
                    return role;
                });
                const roleObj = this.roles.find(item => item.id === Number(this.user.role_id));
                const roleGroup = roleObj && (roleObj.group as RoleGroup);
                this.setCityCountryDetails(response);
                this.setUserForm();
                this.roleId = this.user.user_id ? this.user.role_id : this.roles[0].id;
                this.onChangeRoles(this.roleId);
                if (this.user.user_id && (roleGroup !== RoleGroupEnum.Trainer) && this.user.cities) {
                    this.selectedCities = this.user.cities.map(cityId => String(cityId) );
                }
                this.onChangeCities(this.selectedCities);
                this.setDetailsForRole(roleGroup);
            });
    }

    setCityCountryDetails(response: Array<any>) {
        const countries = response[1] ? response[1]['data'] : null;
        const cityList = response[2] ? response[2] : null;
        const selectedCity: any = find(cityList, {'id': +this.selectedCity});
        this.selectedCountry = selectedCity?.country_id;
        cityList.forEach(city => {
            const country = findIndex(countries, { id: city.country_id });

            if (country >= 0) {
                if (!countries[country].children) {
                    countries[country].children = [];
                    countries[country].text = countries[country].name;
                }
                countries[country].children.push(city);
            }
        });
        this.cities = countries?.filter(country => Array.isArray(country.children)) || [];
        this.countryList = countries?.map(country => {
            return {
                text: country.text || country.name,
                id: country.id
            }
        });
    }

    setUserForm() {
        this.newUser = this.formBuilder.group({
            email: [this.user.user_id ? this.user.email : '', [
                Validators.required, Validators.pattern(regex.email)]
            ],
            cities: [this.user.user_id ? this.user.cities.map(cityId => String(cityId) ) : ''
            ],
            [this.role_id_key]: [this.user.user_id ? this.user.role_id : this.roles[0].id, [
                Validators.required]
            ]
        });
    }

    setDetailsForRole(roleGroup = '') {
        if (roleGroup === RoleGroupEnum.Trainer) {
            this.setDetailsForRoleTrainer();
        } else if (roleGroup === RoleGroupEnum.FranchiseAdmin) {
            this.setDetailsForRoleFranchiseAdmin();
        } else if (roleGroup === RoleGroupEnum.FranchiseAgent) {
            this.setDetailsForFranchiseAgent();
        }
    }

    setDetailsForRoleTrainer() {
        observableForkJoin([
            this.getUser(),
            this.addTrainerFields()
        ])
        .subscribe(([user]) => {
            this.mapUserFields(user as User);
            this.selectedLanguages = this.user.user_id ? this.user.allowedLanguages : [];
            this.selectedLanguages = this.selectedLanguages.map(lang => lang.toString());
            this.selectedLocations = this.user.user_id ? (this.user.allowedLocations || []) : [];
            this.selectedLocations = this.selectedLocations.map(locationId => locationId.toString());
        });
    }

    setDetailsForRoleFranchiseAdmin() {
        observableForkJoin([
            this.getUser(),
            this.addFranchiseAdminFields()
        ])
        .subscribe(([user]) => {
            this.mapUserFields(user as User);
            this.selectedLocations = this.user.user_id ? this.user.allowedLocations : [];
            this.onChangeLocation(this.selectedLocations);
            this.selectedCities = [this.selectedCity].map(String);
        });
    }

    setDetailsForFranchiseAgent() {
        observableForkJoin([
            this.getUser(),
            this.addFranchiseAgentFields()
        ])
        .subscribe(([user]) => {
            this.mapUserFields(user as User);
            const allowedLocations = take(this.user.allowedLocations, 1) || [];
            this.selectedLocations = allowedLocations.length ? allowedLocations : ([this.locations?.[0]?.id] || []);
            this.selectedLanguages = this.user.user_id ? this.user.allowedLanguages : [];
            this.selectedLanguages = this.selectedLanguages.map(lang => lang.toString());
            this.onChangeLanguage(this.selectedLanguages);
            this.selectedCities = [String(this.selectedCity)];
            this.onChangeLocation(this.selectedLocations);
        });
    }

    mapUserFields(user: User) {
        this.user.joining_date = null;
        this.user.allowedLanguages = (user.languages && user.languages.data && user.languages.data.length) ? user.languages.data.map((language) => language.id) : [];
        this.user.allowedLocations = (user.locations && user.locations.data && user.locations.data.length) ? user.locations.data.map((location) => location.id) : [];
        this.isTrainingScheduled = user.trainings?.upcoming_trainings > 0;
    }

    getUser() {
        return this.userManagementService
            .getUser(this.user.user_id);
    }

    getLanguages(): Observable < Select2OptionData[] > {
        if (this.languages) {
            return observableOf(this.languages);
        }

        return this.languageService
            .getActiveLanguages().pipe(
            tap(languages => {
                this.languages = languages;
                this.selectedLanguages.push(String(this.languages[0]));
            }));
    }

    getLocations(roleGroup: RoleGroup, isRevampVersion = false) {
        return this.locationService.getLocationsForUserCreation(LocationTypeEnum.Captain, roleGroup, isRevampVersion);
    }


    getJoiningDate() {
        return observableOf(new Date());
    }

    ngAfterViewInit() {
        this.userModal.show();
        this.userModal.onHidden
            .subscribe(() => this.dynamicModalService.modalOnClose.next(null));
    }

    getCitiesForSelectedCountry(countries: string[] = []) {
        const selectedCountries = countries.reduce((acc: {[key: string]: number | string}, curr) => {
            acc[curr] = +curr;
            return acc;
        }, {});
        let selectedCities = [];
        this.cities?.forEach(country => {
            if(country?.id === selectedCountries?.[country?.id]) {
                selectedCities = [...selectedCities, ...country.children]
            }
        });

        return selectedCities.map(city => city.id);
    }

    onSubmit(form: User) {
        this.setSubmitted = true;
        const roleObj = this.roles.find(item => item.id === Number(form.role_id || form.roleId));
        if (roleObj?.group === RoleGroupEnum.SuperAdmin) {
            form.cities = [];
        } else if (roleObj?.group === RoleGroupEnum.Trainer) {
            const currentCity = JSON.parse(localStorage.getItem('sg_city_id'));
            form.cities = [];
            if (currentCity) form.cities.push(currentCity);
        }
        form.group = roleObj?.group;

        if(this.isRevampVersion) {
            form.joiningDate = null;
        } else {
            form.joining_date = null;
        }
        this.user.user_id ? this.update(form) : this.create(form);
    }

    create(form: User) {
        this.spinnerService.change(true);
        const payload = {
            ...form,
            ...(form.countries && {
                cities: this.getCitiesForSelectedCountry(form.countries)
            }),
            ...(this.isRevampVersion && typeof form.locations === 'string' && {
                locations: [form.locations]
            })
        };
        this.userManagementService.addUser(payload, this.isRevampVersion)
            .subscribe(() => this.onSubmitted('created'), () => this.setSubmitted = false);
    }

    update(form: User) {
        const payload = {
            ...form,
            ...(form.countries && {
                cities: this.getCitiesForSelectedCountry(form.countries)
            }),
            ...(this.isRevampVersion && typeof form.locations === 'string' && {
                locations: [form.locations]
            })
        };
        this.spinnerService.change(true);
        this.userManagementService.updateUser(payload, this.user.user_id, this.isRevampVersion)
            .subscribe(() => this.onSubmitted('updated'), () => this.setSubmitted = false);
    }

    onSubmitted(type ?: string) {
        this.spinnerService.change(false);
        this.toastrService.openToastr(`Successfully ${type} user information`, 'success');
        this.userModal?.hide();
        this.newUser?.reset();
        this.closeModal(true);
    }

    onChangeCities(city: Array<string>) {
        if (city.length) {
            this.newUser.controls['cities']?.setValue(city);
        } else {
            this.newUser.get('cities')?.setValue('');
        }
    }

    onChangeCountry(country: Array<string>) {
        if(country.length) {
            this.newUser.controls['countries']?.setValue(country);
        } else {
            this.newUser.get('countries')?.setValue('');
        }
    }

    onChangeCaptainTypes(captainTypes: Array<string>) {
        if(captainTypes?.length) {
            this.newUser.controls['captainTypes']?.setValue(captainTypes);
        } else {
            this.newUser.get('captainTypes')?.setValue('');
        }
    }

    onChangeRoles(role: any) {
        this.newUser.addControl('cities', new FormControl('', [Validators.required]));
        this.newUser.addControl('locations', new FormControl('', [Validators.required]));
        this.newUser.addControl('captainTypes', new FormControl('', [Validators.required]));
        this.newUser.get('cities')?.setValue([this.selectedCities]);
        this.isCityDisabled = false;
        const roleObj = this.roles.find(item => item.id === Number(role));
        const roleGroup = role && roleObj && roleObj.group;
        if (roleGroup === RoleGroupEnum.SuperAdmin) {
            this.isCityDisabled = true;
            this.newUser.get('cities').setValidators([]);
            this.selectedCities = [];
            this.removeFormFields(['countries', 'captainTypes']);
            if (this.newUser.get('locations')) this.removeTrainerFields();
        } else if (roleGroup === RoleGroupEnum.Trainer) {
            this.openTrainerFields();
            this.newUser.get('cities').setValidators([]);
            this.selectedCities = [];
            this.removeFormFields(['countries', 'captainTypes']);
        } else if (roleGroup === RoleGroupEnum.CountryAdmin) {
            this.isCityDisabled = true;
            const country: any = find(this.cities, {'id': +this.selectedCountry});
            this.selectedCities = country?.children?.map(city => city.id);
            this.newUser.get('cities').setValidators([Validators.required]);
            this.removeFormFields(['countries', 'locations', 'languages', 'captainTypes']);
        } else if (roleGroup === RoleGroupEnum.FranchiseAgent) {
            this.isCityDisabled = true;
            this.openFranchiseAgentFields();
            this.selectedCities = [this.selectedCity].map(String);
            this.newUser.get('cities').setValidators([]);
            this.removeFormFields(['countries', 'captainTypes']);
        } else if (roleGroup === RoleGroupEnum.FranchiseAdmin) {
            this.isCityDisabled = true;
            this.openFranchiseAdminFields();
            this.newUser.get('cities').setValidators([]);
            this.selectedCities = [this.selectedCity].map(String);
            this.removeFormFields(['countries', 'captainTypes']);
            if (this.newUser.get('languages')) this.newUser.removeControl('languages');
        } else if(roleGroup === RoleGroupEnum.AuditingAgent) {
            this.isCityDisabled = true;
            this.selectedCities = [];
            this.selectedCaptainTypes = [];
            this.removeFormFields(['locations', 'cities']);
            this.openAuditAgentFields();
        } else if(roleGroup === RoleGroupEnum.PreprocessingAgent) {
            this.isCityDisabled = true;
            this.selectedCities = [];
            this.selectedCaptainTypes = [];
            this.removeFormFields(['locations', 'cities']);
            this.openPreprocessingAgentFields();
        } else {
            if (this.newUser.get('locations')) this.removeTrainerFields();
            this.newUser.get('cities').setValidators([Validators.required]);
            this.selectedCities = [];
            this.removeFormFields(['countries', 'captainTypes']);
        }

        this.newUser.controls[this.role_id_key].setValue(role);
        this.newUser.get('cities')?.updateValueAndValidity();
    }

    closeModal(data: any) {
        this.onClose?.next(data);
        this.onClose?.complete();
    }

    openAuditAgentFields() {
        this.spinnerService.change(true);
        this.setSelectedCountries();
        this.addCaptainTypeField();
        this.spinnerService.change(false);
    }

    openPreprocessingAgentFields() {
        this.addCaptainTypeField();
    }

    addCaptainTypeField() {
        this.newUser.addControl('captainTypes', new FormControl('', [Validators.required]));
        if(this.captainTypes.length) {
            return;
        }

        this.spinnerService.change(true);
        this.setSelectedCountries();

        observableForkJoin([
            this.userManagementService.getCaptainTypes()
        ]).subscribe(resp => {
            this.captainTypes = resp?.[0] || [];
            this.selectedCaptainTypes = this.user.allowedCaptainTypes?.map((captainType: any) => captainType?.id?.toString());
            this.spinnerService.change(false);
        })
    }

    addTrainerFields() {
        this.isCityDisabled = true;
        return observableForkJoin([
                this.addLocationsField(RoleGroupEnum.Trainer, true),
                this.addLanguagesField(),
                this.addJoiningDateField()
            ]);
    }

    openTrainerFields() {
        this.spinnerService.change(true);
        this.addTrainerFields()
            .subscribe(() => {
                this.spinnerService.change(false);
            });
    }

    addFranchiseAdminFields() {
        this.isCityDisabled = true;
        return observableForkJoin([
                this.addLocationsField(RoleGroupEnum.FranchiseAdmin, false, 'Franchise location')
            ]);
    }

    openFranchiseAdminFields() {
        this.spinnerService.change(true);
        this.addFranchiseAdminFields()
            .subscribe(() => {
                this.spinnerService.change(false);
            });
    }

    addFranchiseAgentFields() {
        this.isCityDisabled = true;
        return observableForkJoin([
                this.addLocationsField(RoleGroupEnum.FranchiseAgent, false, 'Franchise location'),
                this.addLanguagesField()
            ]);
    }

    openFranchiseAgentFields() {
        this.spinnerService.change(true);
        this.addFranchiseAgentFields()
            .subscribe(() => {
                this.spinnerService.change(false);
            });
    }

    addLanguagesField() {
        return this
            .getLanguages().pipe(
            tap(() => {
                this.newUser.addControl('languages', new FormControl('', [Validators.required]));
            }));
    }

    addLocationsField(roleGroup: RoleGroup, shouldSelectedMultipleLocations = false, locationsLabel = 'Locations') {
        return this
            .getLocations(roleGroup, this.isRevampVersion).pipe(
            tap(locations => {
                this.locations = locations;
                this.shouldSelectedMultipleLocations = shouldSelectedMultipleLocations;
                this.locationsLabel = locationsLabel;
                this.newUser.addControl('locations', new FormControl('', [Validators.required]));
            }));
    }

    addJoiningDateField() {
        return this
            .getJoiningDate().pipe(
            tap(joiningDate => {
                const selectedValue = this.user && this.user.joining_date || joiningDate;
                this.newUser.addControl(this.joining_date_key, new FormControl(selectedValue, [Validators.required]));
            }));
    }

    removeTrainerFields() {
        this.newUser.removeControl('languages');
        this.newUser.removeControl('locations');
        this.newUser.removeControl(this.joining_date_key);
    }

    removeFormFields(fields: Array<string>) {
        fields.forEach(field => {
            this.newUser.removeControl(field);
        });
    }

    onChangeLanguage(language: Array<string>) {
        this.newUser.controls['languages'].setValue(language);
    }

    onChangeLocation(locations: Array<string>) {
        this.newUser.controls['locations'].setValue(locations);
    }

    setSelectedCountries() {
        this.newUser.addControl('countries', new FormControl('', [Validators.required]));
        const selectedCities = this.user.cities || [];
        const selectedCountries = [];
        const countryCityMapper = this.cities.reduce((acc: any, curr) => {
            acc[curr.id] = curr.children.map(city => city.id);
            return acc;
        }, {});

        for(const key in countryCityMapper) {
            const isavailable = intersection(countryCityMapper[key], selectedCities);
            isavailable.length && (selectedCountries.push(key.toString()));
        }

        this.selectedCountries = selectedCountries;
        this.newUser.controls['countries'].setValue(selectedCountries);
    }
}
