import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ToastrMessages } from '../constants';
import { DynamicModalService } from '../modals';
import { PromptComponent } from '../modals/prompt/prompt.component';
import { SpinnerService } from '../spinner/spinner.service';
import { ToastrService } from '../toastr/toastr.service';
import { ApiError, ApiErrorResponse, HttpCode, isMessageInApiErrorResponse } from './interfaces';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private reloginPromptSubscription: Subscription;

  constructor(
    private readonly authService: AuthService,
    private readonly dynamicModalService: DynamicModalService,
    private readonly toastrService: ToastrService,
    private readonly spinnerService: SpinnerService,
    private readonly router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((response: ApiErrorResponse) => {
          this.spinnerService.change(false);
          switch (response.status) {
            case HttpCode.BAD_REQUEST:
              this.handleBadRequest(response);
              break;
            case HttpCode.UNAUTHORIZED:
              this.handleUnAuthorized();
              break;
            case HttpCode.REQUEST_ENTITY_TOO_LARGE:
              this.toastrService.openToastr(ToastrMessages.requestLarge, ToastrMessages.error);
              break;
            default:
              this.handleDefault(response);
              break;
          }

          return throwError(response);
        })
      );
  }

  private handleBadRequest(response: ApiErrorResponse) {
    const isTokenError = this.getErrors(response)
      .some(err => err.source && err.source.parameter === 'token');
    if (isTokenError) this.showReloginPrompt();
    else this.handleDefault(response);
  }

  private handleDefault(response: ApiErrorResponse) {
    this.toastrService.openToastr(this.getErrorMessage(response), ToastrMessages.error);
  }

  private handleUnAuthorized() {
    if (this.reloginPromptSubscription) return;
    this.reloginPromptSubscription = this.showReloginPrompt();
    return;
  }

  private showReloginPrompt(): Subscription {
    return this.dynamicModalService
      .setComponentData({
        component: PromptComponent,
        inputs: {
          message: 'Your session has been expired. Please login again to continue using Supply Gate.',
          title: 'Session timed out',
          confirmButton: 'Re-login'
        }
      })
      .pipe(first())
      .subscribe(() => {
        this.authService.logout();
        this.reloginPromptSubscription = null;
      });
  }

  private getErrorMessage(response: ApiErrorResponse): string {
    const error = this.getFirstOrDefault(response);
    return error.title;
  }

  private getErrors(response: ApiErrorResponse): ApiError[] {
    if (response.error && Array.isArray(response.error.errors)) {
      return response.error.errors;
    } else if (isMessageInApiErrorResponse(response)) {
      // If error is CAG like, return the message to display
      return [ {title: response.error.message} ];
    }
    return [];
  }

  private getFirstOrDefault(response: ApiErrorResponse): ApiError {
    const error = this.getErrors(response)[0];
    return error || {
      title: 'Something really bad happened'
    };
  }
}
