import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Analytika } from "@analytika/analytika-web";
import { ANALYTIKA_SESSION_ID, ANALYTIKA_V2_EVENT_DETAILS, SERVICE_NAME } from '../common/constants';
import { isDevelopmentEnv } from './utils';

@Injectable({
  providedIn: 'root'
})
export class AnalytikaV2Service {
  globalAttrs;
  app_url: string;

  constructor() {
    this.app_url = window?.location?.host;
    this.setAnalytika();
    this.setGlobalAttrs();
    this.registerEvents();
  }

  setAnalytika() {
    if (typeof window !== "undefined") {
      const env = isDevelopmentEnv(window?.location?.hostname) ? 'test' : 'prod';
      Analytika?.init(env, false, {}, 10, 10);
    }
  }

  registerEvents() {
    window.addEventListener("pagehide", function () {
      Analytika?.getInstance()?.flush();
    });

    window.addEventListener("beforeunload", function () {
      Analytika?.getInstance()?.flush();
    });

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        Analytika?.getInstance()?.flush();
      }
    });
  }

  setGlobalAttrs() {
    this.globalAttrs = {
      service_name: SERVICE_NAME,
      host: this.app_url,
      host_name: this.app_url,
      session_token: this.setSessionUUID(),
    };
  }

  setSessionUUID() {
    let _sessionUUID = sessionStorage.getItem(ANALYTIKA_SESSION_ID);
    if (!_sessionUUID) {
      _sessionUUID = uuidv4();
      sessionStorage.setItem(ANALYTIKA_SESSION_ID, _sessionUUID);
    }

    return _sessionUUID;
  }

  publish(eventName, eventData) {
    Analytika.publish({
      event_name: eventName,
      event_trigger_time: new Date().getTime(),
      event_version: ANALYTIKA_V2_EVENT_DETAILS[eventName]?.VERSION,
      signature: ANALYTIKA_V2_EVENT_DETAILS[eventName]?.SIGNATURE,
      ...this.globalAttrs,
      ...eventData,
    });
  }
}
