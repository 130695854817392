import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_INITIALIZER, Inject, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { OAuthModule } from 'angular-oauth2-oidc';
import {ExperimentationService} from './shared/experimentation.service';

import {
  AuthGuard,
  AuthService,
  CitiesService,
  LoginAuthGuard,
  PermissionGuard,
  PermissionService,
  RedirectGuard,
  ToastrService
} from '../app/common/index';

import { App } from './app.component';
import { AppConfig } from './app.config';
import { INTERCEPTORS } from './app.interceptors';
import { ROUTES } from './app.routing';
import { AuthHelper } from './common/auth/auth.helper';
import { AppCommonModule } from './common/common.module';
import { DateService } from './common/date/date.service';
import { FeatureService } from './common/feature.service';
import { GetCitiesService } from './common/get-cities.service';
import { DynamicModalModule } from './common/modals/dynamic-modal/dynamic-modal.module';
import { SpinnerService } from './common/spinner/spinner.service';
import { ErrorComponent } from './common/error/error.component';
import { AnalyticsService } from './common/analytics.service';
import { InterceptorLoggedHttpParams, InterceptorRequestConfig } from './common/error-logging/interceptor-http-params';
import { SOURCE_TOKEN_VALIDATION } from './common/error-logging/const/error-logging-sources';
import { AuthErrorComponent } from './common/auth/auth-error/auth-error.component';

import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { HEADER_KEYS, V2_HEADER } from './overview-v2/document-transcription-v2/shared/constants';
import { HealthCheckComponent } from './health-check/health-check.component';
import { AnalytikaV2Service } from './shared/analytika-v2.service';

const APP_PROVIDERS = [
  AppConfig,
  AuthService,
  AuthHelper,
  AuthGuard,
  DateService,
  ToastrService,
  PermissionService,
  PermissionGuard,
  RedirectGuard,
  CitiesService,
  SpinnerService,
  LoginAuthGuard,
  GetCitiesService,
  FeatureService,
  AnalyticsService,
  ExperimentationService,
  AnalytikaV2Service,
];

/**
 * `AppModule` is the main entry point into Angular2's bootstrapping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [
    App,
    ErrorComponent,
    AuthErrorComponent,
    HealthCheckComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppCommonModule,
    BrowserModule,
    DynamicModalModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, { useHash: true, initialNavigation: 'disabled', relativeLinkResolution: 'legacy' }),
    OAuthModule.forRoot()
  ],
  providers: [
    ...APP_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: validateTokenFactory,
      deps: [Injector],
      multi: true
    },
    ...INTERCEPTORS,
  ],
})
export class AppModule {}

export function validateTokenFactory(injector: Injector) {
  return () => {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        const http: HttpClient = injector.get(HttpClient);
        const authService: AuthService = injector.get(AuthService);
        if (!authService.getToken()) return resolve();
        const interceptorConf: InterceptorRequestConfig = {
          errorSource: SOURCE_TOKEN_VALIDATION,
          customAttributes: { token: authService.getToken() }
        };
        const params = new InterceptorLoggedHttpParams(interceptorConf, null );
        const headers = new HttpHeaders({
          [V2_HEADER]: '1',
          [HEADER_KEYS['auth-token-update']]: '1',
          [HEADER_KEYS['sg-auth-token']]: '1',
          ['x-city-id']: localStorage.getItem('sg_city_id') || '',
        });
        http
          .get('/auth/admin/validate-token', { params, headers })
          .subscribe(()=> {resolve()}, (err) => {
            authService.logout();
            resolve();
          });
      });
    });
  };
}
