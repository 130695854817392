window['env'] = window['env'] || {};

export const env = {
  forgotPasswordRedirectLink: window['env']['FORGOT_PASSWORD_CAREEM_URL'] || 'FORGOT_PASSWORD_CAREEM_URL',
  signupLink: window['env']['CAREEM_SIGNUP_LINK'] || 'CAREEM_SIGNUP_LINK',
  providerAccessKey: window['env']['PROVIDER_ACCESS_KEY'],
  agent: window['env']['AGENT'],
  authorization: window['env']['AUTHORIZATION'],
  solutionsBaseUrl: window['env']['SOLUTIONS_BASE_URL'] || 'SOLUTIONS_BASE_URL',
  identityIssuer: window['env']['IDENTITY_ISSUER'] || 'IDENTITY_ISSUER',
  identityClientId: window['env']['IDENTITY_CLIENT_ID'] || 'IDENTITY_CLIENT_ID',
  identityRedirectUri: `#/oauth`,
  identityScope: 'offline_access openid profile edge_captain',
  appName: 'supply-gate-admin-console'
};
