import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';

import { DateService } from '../date/date.service';
import { environment } from '../../../environments/environment';
import { User } from '../../user-management/user';
import { map, catchError, tap } from 'rxjs/operators';
import { ApiResponse } from '../models/response';
import { OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { SpinnerService } from '../spinner/spinner.service';
import { CacheService } from '../cache.service';

@Injectable()
export class AuthService {
  refreshCount = 0;
  private user: User = null;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly dateService: DateService,
    private readonly oauthService: OAuthService,
    private readonly urlHelperService: UrlHelperService,
    private readonly spinnerService: SpinnerService,
    private readonly cacheService: CacheService,
  ) {}

  initOAuthFlow() {
    this.oauthService.configure({
      clientId: environment.identityClientId,
      issuer: environment.identityIssuer,
      redirectUri: `${window.location.protocol}//${window.location.host}/${environment.identityRedirectUri}`,
      scope: environment.identityScope
    });
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setStorage(localStorage);

    const queryParams: any = this.urlHelperService.getHashFragmentParams();
    if (queryParams && queryParams.error && queryParams.error_description) {
      return Promise.reject(queryParams.error_description);
    }

    return this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(didFetchToken => {
        const hash = window.location.hash.substring(1);

        if (!this.oauthService.hasValidAccessToken()) {
          this.oauthService.initImplicitFlow(encodeURIComponent(hash));
        }

        if (didFetchToken || !this.isSGTokenValid()) {
          return this.expirePreviousSGTokenAndFetchNew()
            .toPromise()
            .then(() => this.getCurrentPath(this.oauthService.state || hash));
        }

        return this.getCurrentPath(hash);
    });
  }

  me(): Observable<any> {
    if (this.user !== null) {
      return of(this.user);
    } else {
      return this.httpClient.get(`supply/gate/admin/me`)
      .pipe(
        map(res => res['data'] ),
        map(user => {
          this.user = user;
          return user;
        })
      );
    }
  }

  logout() {
    this.redirectToLogin();
  }

  setExpiry(expiry: string) {
    localStorage.setItem('token_expiry',
      String(this.dateService.getRelativeDate('add', 'seconds', Number(expiry)).getTime()));
  }

  setAllowedCities(cities: Array<number>) {
    localStorage.setItem('allowed_cities', JSON.stringify(cities));
  }

  setCityId(cityId: string) {
    localStorage.setItem('sg_city_id', cityId);
  }

  getCityId(): string | undefined {
    return localStorage.getItem('sg_city_id');
  }

  redirectToLogin() {
    this.cacheService.deleteCache();
    this.oauthService.logOut();
    this.removeToken();
    window.open(environment.identityIssuer, '_self');
  }

  clearSession(): Observable<any> {
    return this.httpClient.post('supply/gate/logout', null);
  }

  redirectToDashboard() {
    this.router.navigateByUrl(`/overview`);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  getCareemAccessToken() {
    return localStorage.getItem('access_token');
  }

  setToken(data: Response) {
    localStorage.setItem('token', data['token'] || data['access_token']);
  }

  decodeTokenToBase64(token: string) {
    const base64 = token.split('.')[1].replace('-', '+').replace('_', '/');
    return JSON.parse(atob(base64));
  }

  setUserInfo(user: string) {
    localStorage.setItem('userInfo', user);
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  getUserRoles() {
    const user = this.getUserInfo();
    return user['roles'];
  }

  decodeToken(token: string): any {
    return this.decodeTokenToBase64(token);
  }

  saveToken(data: Response) {
    this.setToken(data);
    const decodedToken = this.decodeToken(data['token'] || data['access_token']);
    this.setUserInfo(JSON.stringify(decodedToken.user));
    this.setExpiry(data['expires_in']);
    if (decodedToken['allowed_cities']) {
      this.setAllowedCities(decodedToken['allowed_cities']);
    }
  }

  removeToken() {
    localStorage.removeItem('sg_city_id');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
    localStorage.removeItem('token_expiry');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('allowed_cities');
    localStorage.removeItem('careem_access_token');
  }

  getHeadersForServices(isV2: boolean = false, isSGAuthToken: boolean = false) {
    return this.filterHeaders({
      'accessToken': this.getCareemAccessToken(),
      ...(isSGAuthToken && {
        'SG-Auth-Token': this.getToken(),
      }),
      ...(!isSGAuthToken && {
        'Authorization': isV2 ? this.getToken() : `Bearer ${this.getToken()}`,
      })
    });
  }

  getHeadersForCaptainGateway(authToken?: string) {
    const headers = {
      'Authorization': 'bearer ' + (authToken || this.getCareemAccessToken()),
      'SG-Auth-Token': this.getToken(),
      'User-Id': this.getUserInfo() ? (this.getUserInfo()['id']).toString() : null
    };
    const providerAccessKey = environment.providerAccessKey;

    if (providerAccessKey != null) {
      headers['Provider-Access-Key'] = providerAccessKey;
    }

    return this.filterHeaders(headers);
  }

  isSGTokenExpired() {
    const tokenExpiry = Number(localStorage.getItem('token_expiry'));
    return tokenExpiry && tokenExpiry < new Date().getTime();
  }

  private filterHeaders(headers: any) {
    return Object.keys(headers)
      .filter(key => headers[key])
      .filter(key => typeof headers[key] !== 'string'
        || headers[key].substr('Bearer '.length) !== 'null')
      .reduce((headerObj, key) => {
        headerObj[key] = headers[key];
        return headerObj;
      }, { });
  }

  private expirePreviousSGTokenAndFetchNew() {
    this.spinnerService.change(true);
    return forkJoin([
      this.isSGTokenValid() ? this.clearSession().pipe(catchError(() => of(null))) : of(null),
      this.httpClient.post('supply/gate/admin/token', null)
    ])
    .pipe(
      tap(() => this.spinnerService.change(false)),
      map(([logoutResponse, tokenResponse]) => (tokenResponse as ApiResponse<any>).data),
      tap(tokenData => this.saveToken(tokenData))
    );
  }

  private getCurrentPath(path = '/') {
    const queryParamIndex = path.indexOf('?');
    const isQueryParamPresent = queryParamIndex >= 0;
    return {
      path: path.substring(0, isQueryParamPresent ? queryParamIndex : path.length),
      queryParams: this.urlHelperService.parseQueryString(isQueryParamPresent ? path.substring(queryParamIndex + 1) : null)
    };
  }

  private isSGTokenValid(): boolean {
    return this.getToken() && !this.isSGTokenExpired();
  }
}
